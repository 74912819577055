import * as constants from "./constants";

const exampleAction = x => ({
  type: constants.nameHere,
  x
});

export default {
  exampleAction
};
